<template>
  <div id="app">
    <div class="is-login
      ">
      <div class="headerTop">
        <header-top></header-top>
      </div>
      <div class="contenView">
        <div class="app-menu-left">
          <menu-left></menu-left>
        </div>
        <div class="content_main">
          <transition :name="logged ? 'slide-fade' : ''">
            <router-view />
          </transition>
        </div>
      </div>
      <div class="footer">
        <Footer />
      </div>
    </div>
    <div class="not-login">
      <!-- <router-view /> -->
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from "vuex";
// import ls from "local-storage";
import MenuLeft from "@/views/layout/MenuLeft";
import HeaderTop from "@/views/layout/HeaderTop";
import Footer from "@/views/layout/Footer";
// import axios from "axios";
export default {
  name: "App",
  metaInfo () {
    return {
      title: this.title,
      titleTemplate: 'CÔNG TY CỔ PHẦN DOMEA %s',
      showText: false,
    }
  },
  components: {
    MenuLeft,
    HeaderTop,
    Footer
  },
  computed: {
    ...mapGetters("account", ["logged"]),
    ...mapGetters(["path", "full_path"]),
  },
  data() {
    return {
      showText: false,
    };
  },
  mounted() {
  },
  methods: {
    ...mapActions("account", ["getAccountInfo"]),

  },
};
</script>

<style>
.contenView {
  height: 100%;
  min-height: calc(100vh - 50px);
  overflow-x: hidden;
  width: 100%;
}
/*.content_main {
  overflow-y: scroll;
  height: calc(100vh - 68px);
}*/

</style>
