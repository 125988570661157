<template>
  <div class="container_f footer">
    <el-row>
      <el-col :md="9" :xs="24">
        <div class="left">
          <div class="logo">
            <img src="@/static/icon/footer/logo.svg" alt="">
          </div>
          <div style="width: 300px">
            <span>Xây dựng tương lai, mở đường cho sự đổi mới và tiên phong trong mọi lĩnh vực</span>
          </div>
          <div class="logo_list_web">
            <img src="@/static/icon/footer/fb.svg" alt="">
            <img src="@/static/icon/footer/gg.svg" alt="">
            <img src="@/static/icon/footer/tl.svg" alt="">
            <img src="@/static/icon/footer/yt.svg" alt="">
          </div>
        </div>
      </el-col>
      <el-col :md="10" :xs="24">
        <div class="center">
          <div class="title">
            <span>Thông tin liên hệ</span>
          </div>
          <div class="title" style="font-size: 22px !important;">
            <span>CÔNG TY CỔ PHẦN DOMEA</span>
          </div>
          <div class="sub_menu_footer">
            <img src="@/static/icon/footer/phone.svg" alt="">

            <span>0916.39.1688</span>
          </div>
          <div class="sub_menu_footer">
            <img src="@/static/icon/footer/email.svg" alt="">
            <span>info@domea.com</span>
          </div>
          <div class="sub_menu_footer">
            <img src="@/static/icon/footer/layer.svg" alt="">
            <div style="padding-right: 130px" class="mbp0">
              <span>Lô 501, tầng 5 tòa nhà the Golden Palm, số 21 Lê Văn Lương, Phường Nhân Chính, Quận Thanh Xuân, Thành phố Hà Nội, Việt Nam</span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :md="5" :xs="24">
        <div class="right">
          <div class="title">
            <span>Domea</span>
          </div>
          <div class="sub_menu_footer" @click="toShow('about-us')">
            <span>Về Chúng tôi</span>
          </div>
          <div class="sub_menu_footer">
            <span>Lĩnh vực đầu tư</span>
          </div>
          <div class="sub_menu_footer" @click="toShow('new')">
            <span>Tin tức</span>
          </div>
          <div class="sub_menu_footer" @click="toShow('recruitment')">
            <span>Tuyển dụng</span>
          </div>
          <div class="sub_menu_footer" @click="toShow('contact')">
            <span>Liên hệ</span>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="line_footer">
      <span>© 2024 Domea.vn</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "FooterWeb",
    metaInfo() {
      return {
        title: "",
      };
    },
    components: {},
    data() {
      return {
        canSubmit: true,


      };
    },
    computed: {},
    mounted() {},
    methods: {
      toShow(e) {
        this.$router.push({ name: e });
      }
    },
    watch: {},
  };
</script>

<style lang="css" scoped src="@/static/css/layout/footer.css">

</style>
