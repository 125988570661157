import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";

import Meta from "vue-meta";
import auth from "@/router/auth";

Vue.use(VueRouter);

Vue.use(Meta, {
  keyName: "metaInfo", // the component option name that vue-meta looks for meta info on.
  attribute: "data-vue-meta", // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: "data-vue-meta-server-rendered", // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: "vmid", // the property name that vue-meta uses to determine whether to overwrite or append a tag
});

const routes = [
  {
    path: "/",
    name: "home-index",
    component: Home,
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/login",
    name: "account-login",
    component: () => import("@/views/account/Login.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/contact/Index.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/new",
    name: "new",
    component: () => import("@/views/new/Index.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/new/:slug",
    name: "new-show",
    component: () => import("@/views/new/Show.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/recruitment",
    name: "recruitment",
    component: () => import("@/views/recruitment/Index.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/recruitment/:id/:slug",
    name: "recruitment-show",
    component: () => import("@/views/recruitment/Show.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // về chúng tôi
  {
    path: "/about-us",
    name: "about-us",
    component: () => import("@/views/aboutus/List.vue"),
    beforeEnter: auth.requireAuthV2,
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
